import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Navbar from "./components/Navbar";
// import "./index.css";
import PhotoGrid from "./modules/photos/PhotoGrid";
import Home from "./modules/home/Home";
import PhotoView from "./modules/photos/PhotoView";
import Resume from "./modules/resume/Resume";

const App = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <div className="app-container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/photos" element={<PhotoGrid />} />
          <Route path="/photo-view/:category/:id" element={<PhotoView />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
