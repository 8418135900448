import React from "react";
import "../../styles/home/Home.css";

function Home() {
  return (
    <div className="home-container">
      <h1>stas.website</h1>
      <p>coming soon</p>
    </div>
  );
}

export default Home;
