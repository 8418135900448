import React from "react";

const Navbar = () => {
  return (
    <>
      <div id="navbar">resume-ide/stas pakhomov</div>
    </>
  );
};

export default Navbar;
