import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import photoMap from "../../data/photos/photoMap";
import "../../styles/PhotoView.css";

const PhotoView = () => {
  const { id, category } = useParams();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const [currentPhotoId, setCurrentPhotoId] = useState(parseInt(id) || 0);
  const [visiblePhotos, setVisiblePhotos] = useState([]);

  // Filter photos based on category
  const filteredPhotoMap = React.useMemo(() => {
    const filtered =
      category === "all"
        ? photoMap
        : photoMap.filter((photo) => photo.category === category);

    return filtered.map((photo, index) => ({
      ...photo,
      src: `/images/photos/${photo.src}`,
      id: index,
    }));
  }, [category]);

  // Calculate which photos should be visible
  useEffect(() => {
    const loadPhotos = () => {
      const visibleRange = 2; // Number of photos to load above and below
      const newVisiblePhotos = [];

      for (
        let i = currentPhotoId - visibleRange;
        i <= currentPhotoId + visibleRange;
        i++
      ) {
        const photo = filteredPhotoMap.find((p) => p.id === i);
        if (photo) {
          newVisiblePhotos.push(photo);
        }
      }

      setVisiblePhotos(newVisiblePhotos);
    };

    loadPhotos();
  }, [currentPhotoId]);

  // Simple scroll handler
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      const photoHeight = container.clientHeight;
      const scrollTop = container.scrollTop;
      const index = Math.floor((scrollTop + photoHeight / 2) / photoHeight);
      const targetPhoto = visiblePhotos[index];

      if (targetPhoto && targetPhoto.id !== currentPhotoId) {
        setCurrentPhotoId(targetPhoto.id);
        navigate(`/photo-view/${category}/${targetPhoto.id}`, {
          replace: true,
        });
      }
    };

    // Add debounced scroll listener
    let timeoutId = null;
    const debouncedScroll = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(handleScroll, 50);
    };

    container.addEventListener("scroll", debouncedScroll, { passive: true });
    return () => container.removeEventListener("scroll", debouncedScroll);
  }, [currentPhotoId, navigate, visiblePhotos]);

  // Initial scroll to position
  useEffect(() => {
    const container = containerRef.current;
    if (container && visiblePhotos.length > 0) {
      const index = visiblePhotos.findIndex(
        (photo) => photo.id === currentPhotoId
      );
      if (index !== -1) {
        container.scrollTop = index * container.clientHeight;
      }
    }
  }, [currentPhotoId, visiblePhotos]);

  return (
    <div className="photo-view-container" ref={containerRef}>
      <div className="photo-view-inner">
        {visiblePhotos.map((photo) => (
          <div
            key={photo.id}
            className="photo-container"
            id={`photo-${photo.id}`}
          >
            <img
              src={photo.src}
              alt={`Photo ${photo.id}`}
              className="photo"
              loading="lazy"
            />
            <div className="photo-info">
              <span className="photo-id">#{photo.id}</span>
              <span className="photo-category">{photo.category}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoView;
