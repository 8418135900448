import React, { useState, useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import photoMap from "../../data/photos/photoMap";
import "../../styles/PhotoGrid.css";
import { Camera, UtensilsCrossed, Plane, Video, Image } from "lucide-react";

const CategoryIcon = ({ category }) => {
  switch (category.toLowerCase()) {
    case "food":
      return <UtensilsCrossed className="w-6 h-6" />;
    case "travel":
      return <Plane className="w-6 h-6" />;
    case "video":
      return <Video className="w-6 h-6" />;
    default:
      return <Image className="w-6 h-6" />;
  }
};

const PhotoGrid = () => {
  const PHOTOS_PER_PAGE = 6;
  const observerRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [visiblePhotos, setVisiblePhotos] = useState([]);
  const [isResetting, setIsResetting] = useState(false);

  // Get unique categories and add "all" category
  const categories = useMemo(() => {
    const uniqueCategories = [
      "all",
      ...new Set(photoMap.map((photo) => photo.category)),
    ];
    return uniqueCategories;
  }, []);

  // Filter and add IDs to photos based on selected category
  const filteredPhotos = useMemo(() => {
    const filtered =
      selectedCategory === "all"
        ? [...photoMap]
        : photoMap.filter((photo) => photo.category === selectedCategory);
    return filtered.map((photo, index) => ({
      ...photo,
      src: `/images/photos/${photo.src}`, // Update the file path
      offsetId: index, // Maintain the offset for URL purposes
    }));
  }, [selectedCategory]);

  const handleCategoryChange = (category) => {
    if (isResetting) return; // Prevent rapid changes during reset

    setIsResetting(true);
    setTimeout(() => {
      setSelectedCategory(category);
      setVisiblePhotos([]); // Clear photos before new ones are loaded
      setIsResetting(false);
    }, 300); // Match this with your CSS transition duration
  };

  useEffect(() => {
    if (!isResetting) {
      setVisiblePhotos(filteredPhotos.slice(0, PHOTOS_PER_PAGE));
    }
  }, [selectedCategory, filteredPhotos, isResetting]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isResetting) {
          const currentLength = visiblePhotos.length;
          if (currentLength < filteredPhotos.length) {
            setVisiblePhotos((prev) => [
              ...prev,
              ...filteredPhotos.slice(
                currentLength,
                currentLength + PHOTOS_PER_PAGE
              ),
            ]);
          }
        }
      },
      { threshold: 0.5 }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    return () => observer.disconnect();
  }, [visiblePhotos.length, filteredPhotos, isResetting]);

  return (
    <div className="photo-grid-container">
      <div className="category-scroll-container">
        <div className="category-list">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => handleCategoryChange(category)}
              className={`category-item ${
                selectedCategory === category ? "selected" : ""
              }`}
            >
              <div className="category-icon">
                <CategoryIcon category={category} />
              </div>
              <span className="category-name">{category}</span>
            </button>
          ))}
        </div>
      </div>
      <div className={`photo-grid ${isResetting ? "fade-out" : "fade-in"}`}>
        {visiblePhotos.map((photo) => (
          <Link
            key={`${selectedCategory}-${photo.offsetId}`}
            to={`/photo-view/${selectedCategory}/${photo.offsetId}`}
            className="photo-item"
          >
            <img
              src={photo.src}
              alt={`${photo.category} photo ${photo.offsetId}`}
              loading="lazy"
            />
          </Link>
        ))}
        {visiblePhotos.length < filteredPhotos.length && (
          <div
            ref={observerRef}
            className="observer-placeholder"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
};

export default PhotoGrid;
